import React, { useEffect, useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CommonFrame from '../../components/CommonFrame';
import InfluencerSearchButton from '../../components/InfluencerSearchButton';
import JobPostingForm from '../../components/JobPostingForm';
import EditJobForm from '../job-manager/components/EditJobForm';
import JobApplicants from './JobApplicants';
import { useAuth } from '../../contexts/authContext';

const JobManagerPage = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showApplicants, setShowApplicants] = useState(false);
  const [showInfluencerSearch, setShowInfluencerSearch] = useState(false);
  const [showEditJob, setShowEditJob] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch('/api/jobs', {
          method: 'GET',
          credentials: 'include',
        });
        if (!response.ok) throw new Error('Failed to fetch jobs');
        const data = await response.json();
        setJobs(data);
      } catch (e) {
        console.error("Fetching jobs failed:", e);
      }
    };
    fetchJobs();
  }, [currentUser]);

  const handleJobClick = (job) => {
    setSelectedJob(job);
    setShowInfluencerSearch(false);
    setShowEditJob(false);
    setShowApplicants(false);
    setShowBackButton(true);
  };

  const handleEditJob = () => {
    setShowEditJob(true);
  };

  const handleShowApplicants = () => {
    setShowApplicants(true);
  };

  const handleBackToJobPosting = () => {
    setSelectedJob(null);
    setShowEditJob(false);
    setShowInfluencerSearch(false);
    setShowApplicants(false);
    setShowBackButton(false);
    setResetKey((prevKey) => prevKey + 1);
  };

  return (
      <CommonFrame key={resetKey} items={jobs} onSelectItem={handleJobClick}>
        {showBackButton && (
            <Button
                variant="contained"
                onClick={handleBackToJobPosting}
                startIcon={<ArrowBackIcon />}
                sx={{ mb: 2 }}
            >
              Back to Job Posting
            </Button>
        )}

        {selectedJob && !showInfluencerSearch && !showEditJob && !showApplicants && (
            <>
              <Box mb={2}>
                <Typography variant="body1" color="text.secondary">
                  <strong>company_id:</strong> {selectedJob.company_id}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>title:</strong> {selectedJob.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>description:</strong> {selectedJob.description}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>location:</strong> {selectedJob.location}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>tags:</strong> {selectedJob.tags.join(', ')}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>is_public:</strong> {selectedJob.is_public ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>status:</strong> {selectedJob.status}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>date_posted:</strong> {new Date(selectedJob.date_posted).toLocaleString()}
                </Typography>
              </Box>
              <Typography variant="h6">{selectedJob.title}</Typography>
              <Button variant="contained" color="primary" onClick={handleEditJob}>
                Edit Job
              </Button>
              <Button variant="contained" color="secondary" onClick={handleShowApplicants} sx={{ ml: 2 }}>
                View Applications
              </Button>
              <InfluencerSearchButton jobId={selectedJob.id} onSearchClick={() => setShowInfluencerSearch(true)} />
            </>
        )}

        {showApplicants && selectedJob && <JobApplicants jobId={selectedJob.id} />}

        {!selectedJob && !showInfluencerSearch && !showEditJob && !showApplicants && (
            <JobPostingForm onJobPost={(newJob) => setJobs((prevJobs) => [...prevJobs, newJob])} />
        )}
      </CommonFrame>
  );
};

export default JobManagerPage;
