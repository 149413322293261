// <--------------- App.js - Contains all routes used within react enviroment --------------->
// App.js is the main file that contains all the routes used within the react enviroment.
// It is responsible for handling the different routes and rendering the appropriate components.
// Important Note: Any new pages or components that are created must be added to the routes in order to be used.
// For further details, see the routes below.

import Login from "./components/auth/login";
import Register from "./components/auth/register";

import Header from "./components/header";
import Home from "./components/home";
import ProfilePage from "./components/profile_page";

import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";
import { Box } from "@mui/material";
import LandingPage from "./components/landing_page/LandingPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import JobManagerPage from "./pages/job-manager/JobManagerPage";
import JobOffersPage from "./components/JobOffersPage";
import CampaignPage from "./components/campaign/CampaignPage";
import JobEditForm from "./pages/job-manager/components/EditJobForm"
import JobBoard from "./components/jobBoard/JobBoard";
import CustomStepper from "./components/newUserProcess/CustomStepper";
import CssBaseline from '@mui/material/CssBaseline';  // Import CssBaseline
import ContactPage from "./components/Contact/ContactPage";
import React, { useEffect } from "react";





function App() {
    const theme = createTheme({
        palette: {
            // Base colors
            background: {
                default: '#000000',
                //default: '#f5f5f5', // A light, neutral background
            },
            primary: {
                main: '#EECA74'
                //main: '#f4cd2a', //first gold
             //   main: '#8e44ad', // A bold, distinctive purple for accents
            },
            secondary: {
                main: '#EECA74'
                //main: '#e49ea9'
                //main: '#34495e', // A deep, rich blue for contrasting elements
            },
            text: {
                primary: '#FFFFFF',
                secondary: '#FFFFFF'
            },
            // Additional colors as needed
        },
        typography: {
            // Custom typography for that unique hipster feel
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeightLight: 400,
            fontWeightRegular: 500,
            fontWeightMedium: 600,
            fontWeightBold: 700,
            allVariants: {
                color: '#FFFFFF', // All text will default to white unless overridden
            },
            // Add more styles as needed
        },
        // Additional theming options as needed
    });

    useEffect(() => {
        console.log('Supabase URL in App.js:', process.env.REACT_APP_SUPABASE_URL);
        console.log('Supabase Anon Key in App.js:', process.env.REACT_APP_SUPABASE_ANON_KEY);
    }, []);

    const routesArray = [
        {
            path: "*",
            element: <LandingPage />,
        },
        {
            path: "/contact",
            element: <ContactPage />,  // New route for the Contact page
        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/register",
            element: <Register />,
        },
        {
            path: "/CustomStepper",  // Path for the landing page
            element: <CustomStepper />,  // Your landing page component
        },

        {
            path: "/home",
            element: <Home />,
        },
        {
            path: "/profile_page",
            element: <ProfilePage />,
        },
        {
            path: "/job-manager/components",
            element: <JobManagerPage />,
        },
        {
            path: "/jobofferspage",
            element: <JobOffersPage />
        },
        {
            path: "/campaign",
            element: <CampaignPage />,
        },
        {
            path: "/jobboard",
            element: <JobBoard />, // New route for the Job Board
        },
        {
            path: "/job-manager/components/EditJobForm",
            element: <JobEditForm />
        }

    ];

    let routesElement = useRoutes(routesArray);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <Header />
                <Box
                    sx={{
                        width: '100%', // Full width
                        height: '100vh', // Full viewport height
                        display: 'flex', // Use flexbox
                        flexDirection: 'column', // Column direction
                        paddingTop: "150px"
                    }}
                >
                    {routesElement}
                </Box>
            </AuthProvider>
        </ThemeProvider>

    );
}

export default App;