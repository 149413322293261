import React, { useState } from 'react';
import {
    Button,
    Typography,
    Dialog,
    TextField,
    DialogActions,
    Box,
    useTheme,
} from '@mui/material';

export default function GetStartedButton() {
    const theme = useTheme(); // Access theme to pull colors
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setName('');
        setEmail('');
        setErrorMessage('');
        setSuccessMessage('');
    };

    const handleSubmit = async () => {
        if (!name || !email) {
            setErrorMessage('Both fields are required.');
            return;
        }

        try {
            const response = await fetch('/api/email-list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email }),
            });

            if (response.ok) {
                setSuccessMessage('You have successfully signed up!');
                setName('');
                setEmail('');
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.message || 'Something went wrong. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={handleOpen}
                sx={{
                    backgroundColor: theme.palette.primary.main, // Gold background
                    color: 'black', // Black text for button
                    '&:hover': {
                        backgroundColor: theme.palette.primary.dark, // Darker gold on hover
                    },
                }}
            >
                <Typography
                    fontWeight="fontWeightRegular"
                    sx={{
                        color: 'black', // Black text for button
                    }}
                >
                    Get Started
                </Typography>
            </Button>

            {/* Modal Dialog */}
            <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.default, // Black background
                        color: theme.palette.text.primary, // White text
                        border: `2px solid ${theme.palette.text.primary}`, // White border
                        borderRadius: '8px', // Rounded corners for the box
                        p: 3, // Padding inside the box
                    }}
                >
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        textAlign="center"
                        mb={2}
                    >
                        EGO is coming early 2025
                    </Typography>
                    <Typography
                        variant="body1"
                        textAlign="center"
                        mb={3}
                    >
                        Sign up to get notified when we launch!
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            InputProps={{
                                style: { color: theme.palette.text.primary }, // White text
                            }}
                            InputLabelProps={{
                                style: { color: theme.palette.text.primary }, // White label
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.text.primary, // White border
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main, // Gold border on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main, // Gold border when focused
                                    },
                                },
                            }}
                        />
                        <TextField
                            label="Email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            InputProps={{
                                style: { color: theme.palette.text.primary }, // White text
                            }}
                            InputLabelProps={{
                                style: { color: theme.palette.text.primary }, // White label
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.text.primary, // White border
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main, // Gold border on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main, // Gold border when focused
                                    },
                                },
                            }}
                        />
                        {errorMessage && (
                            <Typography color="error" sx={{ textAlign: 'center', mt: 1 }}>
                                {errorMessage}
                            </Typography>
                        )}
                        {successMessage && (
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    mt: 1,
                                    color: theme.palette.primary.main, // Gold success message
                                }}
                            >
                                {successMessage}
                            </Typography>
                        )}
                    </Box>
                    <DialogActions
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 3,
                            p: 0,
                        }}
                    >
                        <Button
                            onClick={handleClose}
                            sx={{
                                color: theme.palette.primary.main, // Gold text
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            sx={{
                                backgroundColor: theme.palette.primary.main, // Gold button
                                color: 'black', // Black text
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.dark, // Darker gold on hover
                                },
                            }}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}
