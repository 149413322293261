// newChatRoomDialog.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

function NewChatRoomDialog({ open, setOpen, currentUserId, renderTrigger, setRenderTrigger }) {
    const [username, setUsername] = useState('');
    const userType = localStorage.getItem('userType');
    const usernameSelf = localStorage.getItem('userName');
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreateChatroom = async () => {
        handleClose();
        try {
            const response = await fetch('/api/createNewChatRoom', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    currentUserId,
                    currentUserType: userType,
                    invitedUser: username,
                    currentUserName: usernameSelf
                }),
            });
            const result = await response.json();
            if (response.ok) {
                if (result.message === "Existing chatroom found") {
                    navigate(`/chat/${result.chatroomId}`);
                    setRenderTrigger(!renderTrigger);
                }
            }
            setUsername('');
        } catch (error) {
            console.error('Failed to create chat room:', error);
            setUsername('');
        }
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Start a New Chatroom</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Username"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleCreateChatroom}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default NewChatRoomDialog;
