// LandingPage.js
import React from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import GetStartedButton from "./GetStartedButton";
import FeatureGrid from "./FeatureGrid";
import backgroundImage from './back.svg';

export default function LandingPage() {
    const initialLaunchFeatures = [
        "Job/Campaign Posting",
        "Chat Feature",
        "Influencer Matching",
        "Analytics",
        "Calendar",
        "In App Payment",
    ];

    const comingSoonFeatures = [
        "AI Driven Feedback",
        "iOS App",
        "Docusign Integration",
        "Influencer Managed Accounts ",
        "AI Content Creation",
        "More on the way...",
    ];

    return (
        <>
            {/* Hero Section Frame */}
            <Container
                maxWidth="lg"
                xs={12}
                sx={{
                    position: 'relative',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    py: 4,
                    overflow: 'hidden',
                }}
            >
                {/* Background Image */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        height: '90%',
                        zIndex: -1,
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                />

                {/* Scalable Content */}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                transform: 'scale(0.9)', // Start smaller and scale
                                '@media (max-width: 1200px)': {
                                    transform: 'scale(0.8)', // Smaller for medium screens
                                },
                                '@media (max-width: 900px)': {
                                    transform: 'scale(0.7)', // Smaller for small screens
                                },
                                '@media (max-width: 600px)': {
                                    transform: 'scale(0.6)', // Smallest for mobile
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2, // Add space between elements
                            }}
                        >
                            <Typography fontWeight="fontWeightBold" variant="h1" gutterBottom>
                                EGO
                            </Typography>
                            <Typography fontWeight="fontWeightBold" variant="h3" gutterBottom>
                                Engage - Grow - Optimize.
                            </Typography>
                            <Typography fontWeight="fontWeightLight" variant="h5">
                                Everything you need to manage your brand deals.
                            </Typography>
                            {/* Call to action button */}
                            <Grid item xs={12} textAlign="center">
                                <GetStartedButton />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            {/* Mission Section Frame */}
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 8, // Padding for vertical spacing
                    color: '#FFFFFF',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        width: '100%',
                    }}
                >
                    <Box sx={{ flex: 1, height: '2px', backgroundColor: '#EECA74' }} /> {/* Left line */}
                    <Typography variant="h5" sx={{ color: '#FFFFFF' }}>Mission</Typography> {/* Center text */}
                    <Box sx={{ flex: 1, height: '2px', backgroundColor: '#EECA74' }} /> {/* Right line */}
                </Box>
            </Container>

            {/* About Us Section Frame */}
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 8, // Padding for vertical spacing
                    color: '#FFFFFF',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        width: '100%',
                    }}
                >
                    <Box sx={{ flex: 1, height: '2px', backgroundColor: '#EECA74' }} /> {/* Left line */}
                    <Typography variant="h5" sx={{ color: '#FFFFFF' }}>About Us</Typography> {/* Center text */}
                    <Box sx={{ flex: 1, height: '2px', backgroundColor: '#EECA74' }} /> {/* Right line */}
                </Box>
            </Container>


            {/* Initial Launch Features Section */}
            <Container maxWidth="lg">
                <FeatureGrid title="Initial Launch Features" features={initialLaunchFeatures} />
            </Container>

            {/* Coming Soon Features Section */}
            <Container maxWidth="lg">
                <FeatureGrid title="Coming Soon" features={comingSoonFeatures} />
            </Container>
        </>
    );
}
