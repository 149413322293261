import React, { useState, useEffect } from 'react';
import CommonFrame from './CommonFrame';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

const JobOffersPage = () => {
    const [jobOffers, setJobOffers] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobAccepted, setJobAccepted] = useState(false);
    const [checkingAcceptance, setCheckingAcceptance] = useState(false);

    useEffect(() => {
        fetchJobOffers();
    }, []);

    const fetchJobOffers = async () => {
        try {
            const response = await fetch(`/api/jobOffers`, {
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setJobOffers(data);
            } else {
                throw new Error('Failed to fetch job offers');
            }
        } catch (error) {
            console.error('Error fetching job offers:', error);
        }
    };

    const handleJobSelection = (jobObject) => {
        setSelectedJob(jobObject);
        setJobAccepted(false);
        setCheckingAcceptance(true);
        checkIfJobAccepted(jobObject.job_id);
    };

    const checkIfJobAccepted = async (jobId) => {
        try {
            const response = await fetch(`/api/checkJobAcceptance`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ jobId }),
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setJobAccepted(data.isAccepted || false);
            }
        } catch (error) {
            console.error('Error checking job acceptance status:', error);
        } finally {
            setCheckingAcceptance(false);
        }
    };

    const handleAcceptJob = async () => {
        if (!selectedJob) {
            alert('Please select a job to accept.');
            return;
        }

        try {
            const response = await fetch('/api/acceptJob', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ jobId: selectedJob.job_id }),
                credentials: 'include'
            });
            const data = await response.json();
            if (data.message === 'Job accepted successfully') {
                alert(`Accepted job: ${selectedJob.title}`);
                setJobAccepted(true);
            } else {
                alert('Failed to accept job.');
            }
        } catch (error) {
            console.error('Error accepting job:', error);
        }
    };

    const handleRejectJob = async () => {
        if (!selectedJob) {
            alert('Please select a job to reject.');
            return;
        }
        try {
            const response = await fetch('/api/rejectJobOffer', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ jobId: selectedJob.job_id }),
                credentials: 'include'
            });
            const data = await response.json();
            if (data.message === 'Job offer rejected successfully') {
                alert(`Rejected job: ${selectedJob.title}`);
                fetchJobOffers();
                setSelectedJob(null);
            } else {
                alert('Failed to reject job.');
            }
        } catch (error) {
            console.error('Error rejecting job offer:', error);
        }
    };

    const jobDetailsUI = (
        <Container maxWidth="sm" sx={{ marginTop: 2 }}>
            {selectedJob && (
                <>
                    <Typography variant="h6" textAlign="center">
                        Selected Job: {selectedJob.title || 'Untitled Job'}
                    </Typography>
                    <Card variant="outlined" sx={{ marginTop: 2, backgroundColor: 'black' }}>
                        <CardContent>
                            <Typography variant="body1"><strong>Description:</strong> {selectedJob.description || 'No description available'}</Typography>
                            <Typography variant="body1"><strong>Location:</strong> {selectedJob.location || 'Not specified'}</Typography>
                            <Typography variant="body1"><strong>Tags:</strong> {selectedJob.tags ? selectedJob.tags.join(', ') : 'No tags'}</Typography>
                            <Typography variant="body1"><strong>Is Public:</strong> {selectedJob.is_public ? 'Yes' : 'No'}</Typography>
                            <Typography variant="body1"><strong>Status:</strong> {selectedJob.status || 'Not specified'}</Typography>
                            <Typography variant="body1"><strong>Date Posted:</strong> {selectedJob.date_posted ? new Date(selectedJob.date_posted).toLocaleString() : 'Unknown'}</Typography>
                            {selectedJob.message && (
                                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                    <strong>Message from Company:</strong> {selectedJob.message}
                                </Typography>
                            )}
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'center', marginBottom: 2 }}>
                            <Button variant="contained" color="primary" onClick={handleAcceptJob} sx={{ marginRight: '10px' }}>
                                Accept Job!
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleRejectJob}>
                                Reject Job
                            </Button>
                        </CardActions>
                    </Card>
                </>
            )}
            {!selectedJob && (
                <Typography textAlign="center">Please select a job to see details.</Typography>
            )}
        </Container>
    );

    const jobActionUI = checkingAcceptance ? (
        <Typography variant="h6" textAlign="center" marginTop={2}>
            Checking job acceptance status...
        </Typography>
    ) : jobAccepted ? (
        <Typography variant="h5" textAlign="center" marginTop={2}>
            You have accepted this job
        </Typography>
    ) : (
        jobDetailsUI
    );

    return (
        <Container maxWidth={false}>
            <CommonFrame items={jobOffers} onSelectItem={handleJobSelection}>
                {jobActionUI}
            </CommonFrame>
        </Container>
    );
};

export default JobOffersPage;
