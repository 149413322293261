import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, List, ListItem, ListItemText, Chip, Button } from '@mui/material';
import ApplyToJob from './ApplyToJob';

const JobBoard = ({ influencerTags = [] }) => {
    const [jobs, setJobs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null); // Track selected job

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch('/api/allJobs');
                const data = await response.json();
                setJobs(data);
                setFilteredJobs(data);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };

        fetchJobs();
    }, []);

    useEffect(() => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        const filtered = jobs.filter(job =>
            job.title.toLowerCase().includes(lowerSearchTerm) ||
            job.description.toLowerCase().includes(lowerSearchTerm)
        );

        const sortedJobs = filtered.sort((a, b) => {
            const aMatches = a.tags.filter(tag => influencerTags.includes(tag)).length;
            const bMatches = b.tags.filter(tag => influencerTags.includes(tag)).length;

            if (bMatches === aMatches) {
                return new Date(b.date_posted) - new Date(a.date_posted);
            }
            return bMatches - aMatches;
        });

        setFilteredJobs(sortedJobs);
    }, [searchTerm, jobs, influencerTags]);

    if (selectedJob) {
        return (
            <ApplyToJob job={selectedJob} onBack={() => setSelectedJob(null)} />
        );
    }

    return (
        <Box>
            <Typography variant="h4" gutterBottom>Job Board</Typography>
            <TextField
                label="Search Jobs"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#EECA74',
                        },
                        '&:hover fieldset': {
                            borderColor: '#EECA74',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#EECA74',
                        },
                    },
                    input: { color: '#FFFFFF' },
                    label: { color: '#EECA74' }
                }}
            />
            <List>
                {filteredJobs.map((job, index) => (
                    <ListItem
                        key={index}
                        divider
                        onClick={() => setSelectedJob(job)} // Set the selected job
                        sx={{
                            border: '2px solid #EECA74',
                            borderRadius: '8px',
                            mb: 2,
                            padding: 2,
                            backgroundColor: '#000000',
                            cursor: 'pointer',
                        }}
                    >
                        <ListItemText
                            primary={
                                <Typography variant="h6" sx={{ color: '#EECA74' }}>
                                    {job.title}
                                </Typography>
                            }
                            secondary={
                                <>
                                    <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                                        {job.description}
                                    </Typography>
                                    <Box sx={{ mt: 1 }}>
                                        {job.tags.map((tag, idx) => (
                                            <Chip
                                                key={idx}
                                                label={tag}
                                                sx={{
                                                    mr: 1,
                                                    color: '#000000',
                                                    backgroundColor: '#EECA74',
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default JobBoard;
