import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { signOut } from '../../supabase/auth';
import { Box, ButtonBase, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles'; // Import to access theme palette
import logo from './Egologo.png';

const Header = () => {
    const navigate = useNavigate();
    const { currentUser, setCurrentUser } = useAuth();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const theme = useTheme(); // Access the theme to use palette colors

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleSignOut = async () => {
        try {
            await signOut(); // Supabase sign out
            setCurrentUser(null);
            console.log("User is signed out");
            navigate('/login');
        } catch (error) {
            console.error('Error signing out:', error.message);
        }
    };

    const renderMenuItems = () => (
        <List
            sx={{
                bgcolor: theme.palette.background.default, // Black background
                width: 250,
                p: 0, // Remove padding
            }}
        >
            <ListItem
                button
                component={RouterLink}
                to="/contact"
            >
                <ListItemText
                    primary="Contact"
                    sx={{
                        '& .MuiTypography-root': {
                            color: theme.palette.primary.main, // Override text to gold
                        },
                    }}
                />
            </ListItem>
            {currentUser ? (
                <ListItem button onClick={handleSignOut}>
                    <ListItemText
                        primary="Logout"
                        sx={{
                            '& .MuiTypography-root': {
                                color: theme.palette.primary.main, // Override text to gold
                            },
                        }}
                    />
                </ListItem>
            ) : (
                <>
                    <ListItem button component={RouterLink} to="/login">
                        <ListItemText
                            primary="Login"
                            sx={{
                                '& .MuiTypography-root': {
                                    color: theme.palette.primary.main, // Override text to gold
                                },
                            }}
                        />
                    </ListItem>
                    <ListItem button component={RouterLink} to="/register">
                        <ListItemText
                            primary="Register New Account"
                            sx={{
                                '& .MuiTypography-root': {
                                    color: theme.palette.primary.main, // Override text to gold
                                },
                            }}
                        />
                    </ListItem>
                </>
            )}
        </List>
    );


    return (
        <AppBar
            position="fixed"
            sx={{
                zIndex: 1201,
                backgroundColor: theme.palette.background.default, // Use black background from theme
                boxShadow: `0px 4px 8px 2px ${theme.palette.primary.main}`, // Gold shadow
                pl: 2,
            }}
        >
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {/* Logo and Title */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ButtonBase
                        component={RouterLink}
                        to="*"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            pr: 1,
                            '&:hover': { opacity: 0.8 },
                        }}
                    >
                        <Box
                            component="img"
                            src={logo}
                            alt="AffiliateEGO Logo"
                            sx={{ height: 40 }}
                        />
                    </ButtonBase>
                    <Typography fontWeight="fontWeightMedium" variant="h5">
                        EGO
                    </Typography>
                </Box>

                {/* Hamburger Menu for Small Screens */}
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <IconButton
                        edge="end"
                        onClick={toggleDrawer(true)}
                        sx={{ color: theme.palette.primary.main }} // Gold color for icon
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>

                {/* Full Navigation for Larger Screens */}
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Button color="primary" component={RouterLink} to="/contact">
                        Contact
                    </Button>
                    {currentUser ? (
                        <Button color="primary" onClick={handleSignOut}>
                            Logout
                        </Button>
                    ) : (
                        <>
                            <Button color="primary" component={RouterLink} to="/login">
                                Login
                            </Button>
                            <Button color="primary" component={RouterLink} to="/register">
                                Register New Account
                            </Button>
                        </>
                    )}
                </Box>
            </Toolbar>

            {/* Drawer for Small Screens */}
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                PaperProps={{
                    sx: {
                        top: 64, // Start where the header ends
                        bgcolor: theme.palette.background.default, // Black background
                        maxHeight: 'auto', // Fit only as much as needed
                    },
                }}
            >
                {renderMenuItems()}
            </Drawer>
        </AppBar>
    );
};

export default Header;
