// client/src/components/JobPostingForm.js
import React, { useState } from 'react';
import { Checkbox, FormControlLabel, TextField, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';

const JobPostingForm = ({ onJobPost }) => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleAddTag = () => {
        if (newTag && !tags.includes(newTag)) {
            setTags([...tags, newTag]);
            setNewTag('');
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = {
                title,
                description,
                location,
                tags,
                isPublic,
            };
            const response = await fetch('/api/postJob', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData), // Removed explicit companyId here
                credentials: 'include',  // Include cookies for session
            });

            if (response.ok) {
                const newJob = await response.json();
                onJobPost(newJob);
                setTitle('');
                setDescription('');
                setLocation('');
                setTags([]);
                setIsPublic(false);

            } else {
                console.error('Error posting the job:', response.statusText);
            }
        } catch (error) {
            setErrorMessage(`Error posting the job: ${error.message}`);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                label="Job Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                fullWidth
                margin="normal"
            />
            <TextField
                label="Job Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                fullWidth
                multiline
                rows={4}
                margin="normal"
            />
            <TextField
                label="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
                fullWidth
                margin="normal"
            />
            <div>
                <label htmlFor="tags">Tags:</label>
                <TextField
                    id="newTag"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="Add a tag"
                    size="small"
                    margin="dense"
                />
                <Button onClick={handleAddTag} variant="contained" size="small">
                    Add Tag
                </Button>
                <div>
                    {tags.map((tag, index) => (
                        <span key={index} style={{ marginRight: '5px' }}>
                            {tag}{' '}
                            <Button
                                size="small"
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleDeleteTag(tag)}
                            >
                                x
                            </Button>
                        </span>
                    ))}
                </div>
            </div>
            <FormControlLabel
                control={<Checkbox checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} />}
                label="Make job public (Visible to influencers)"
            />
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            <Button type="submit" variant="contained" color="primary">
                Post Job
            </Button>
        </form>
    );
};

export default JobPostingForm;
