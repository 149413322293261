import { supabase } from './supabaseClient';

export async function signUpWithEmail(email, password) {
    const { user, error } = await supabase.auth.signUp({
        email,
        password,
    });

    if (error) throw error;
    return user;
}

export async function signInWithEmail(email, password) {
    const { session, error } = await supabase.auth.signIn({
        email,
        password,
    });
    console.log("Sign-in session:", session, "Error:", error);

    if (error) throw error;
    return session;
}

export async function signOut() {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
}
