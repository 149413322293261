import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/authContext';
import { Typography, Box, Drawer, Tabs, Tab } from '@mui/material';
import Person2Icon from '@mui/icons-material/Person2';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WorkIcon from '@mui/icons-material/Work';
import MessageIcon from '@mui/icons-material/Message';
import InventoryIcon from '@mui/icons-material/Inventory';
import PushPinIcon from '@mui/icons-material/PushPin';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CampaignIcon from '@mui/icons-material/Campaign';
import CustomStepper from "../newUserProcess/CustomStepper";
import PostJobPage from "../../pages/job-manager/JobManagerPage";
import JobOffersPage from "../JobOffersPage.js";
import ProfilePage from "../profile_page/index";
import Messenger from "../messenger/messenger";
import CampaignPage from "../campaign/CampaignPage";
import BrandDealTableInfluencer from './BrandDealTableInfluencer.jsx';
import BrandDealTableCompany from './BrandDealTableCompany.jsx';
import InfluencerTrends from './InfluencerTrends.jsx';
import CompanyTrends from './CompanyTrends.jsx';
import WelcomeBoard from './WelcomeBoard.jsx';
import JobBoard from '../jobBoard/JobBoard';

const Home = () => {
    const { currentUser, loading } = useAuth();
    const [userType, setUserType] = useState('');
    const [userData, setUserData] = useState({});
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        if (loading || !currentUser) return; // Wait until loading is false and currentUser is set

        const handleRender = async () => {
            try {
                const response = await fetch('/api/getUserType', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setUserType(data.userType);
                    setUserData(data.userData);
                } else {
                    console.error('Error fetching user data:', response.statusText);
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        handleRender();
    }, [currentUser, loading]); // Re-run when currentUser updates

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const renderTabContent = () => {
        if (!currentUser) {
            return <Typography variant="h5">Loading...</Typography>; // Show loading message while currentUser is null
        }

        // Explicitly map selectedTab based on userType
        if (selectedTab === 0) {
            return (
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    <WelcomeBoard userName={userData.name || userData.userName || currentUser.email} />
                    {userType === 'influencer' && (
                        <>
                            <InfluencerTrends />
                            <BrandDealTableInfluencer id={currentUser.id} />
                        </>
                    )}
                    {userType === 'company' && (
                        <>
                            <CompanyTrends />
                            <BrandDealTableCompany id={currentUser.id} />
                        </>
                    )}
                </Typography>
            );
        }

        if (userType === 'company') {
            switch (selectedTab) {
                case 1:
                    return <PostJobPage />;
                case 2:
                    return <CampaignPage />;
                case 3:
                    return <JobBoard influencerTags={userData.tags || []} />;
                case 4:
                    return <Messenger />;
                case 5:
                    return <ProfilePage />;
                default:
                    return <Typography variant="h5">Select a tab</Typography>;
            }
        }

        if (userType === 'influencer') {
            switch (selectedTab) {
                case 1:
                    return <JobOffersPage />;
                case 2:
                    return <JobBoard influencerTags={userData.tags || []} />;
                case 3:
                    return <Messenger />;
                case 4:
                    return <ProfilePage />;
                default:
                    return <Typography variant="h5">Select a tab</Typography>;
            }
        }
    };


    const filteredTabs = [
        { label: 'Dashboard', icon: <SpaceDashboardIcon /> },
        ...(userType === 'company' ? [{ label: 'Post Job', icon: <PushPinIcon /> }] : []),
        ...(userType === 'company' ? [{ label: 'Campaign', icon: <CampaignIcon /> }] : []),
        ...(userType === 'influencer' ? [{ label: 'Job Offers', icon: <WorkIcon /> }] : []),
        { label: 'Job Board', icon: <ContentPasteIcon /> },
        { label: 'Messages', icon: <MessageIcon /> },
        { label: 'Profile', icon: <Person2Icon /> },
    ];


    if (loading) {
        return (
            <Box sx={{ alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
                <Typography variant="h3" fontWeight="fontWeightLight" textAlign="center">
                    Loading your account...
                </Typography>
            </Box>
        );
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                variant="permanent"
                sx={{
                    width: 240,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: 240,
                        backgroundColor: '#000', // Black background for sidebar
                        color: 'white',           // White text color for sidebar
                        boxShadow: '0px 0px 10px 2px #EECA74', // Gold shadow along the right side
                        borderRight: '1px solid #EECA74',       // Gold border
                    },
                }}
            >
                <Box sx={{ flexGrow: 1, p: 3, mt: 8 }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={selectedTab}
                        onChange={handleTabChange}
                        sx={{
                            color: 'white', // Set text color to white
                            '& .Mui-selected': { color: '#EECA74' }, // Set selected color to gold
                            '& .MuiTabs-indicator': { backgroundColor: '#EECA74' }, // Gold indicator for the selected tab
                        }}
                    >
                        {filteredTabs.map((tab, index) => (
                            <Tab
                                key={index}
                                icon={tab.icon}
                                label={tab.label}
                                sx={{
                                    color: selectedTab === index ? '#EECA74' : 'white', // Selected tab gold, others white
                                    '&:hover': {
                                        color: '#EECA74', // Gold text on hover
                                    },
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                {/* Render Tab Content */}
                {renderTabContent()}
            </Box>
        </Box>
    );
};

export default Home;

