import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { Typography, Avatar, Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Chip } from '@mui/material';

const ProfilePage = () => {
    const { currentUser } = useAuth();
    const [editMode, setEditMode] = useState(false);
    const [profile, setProfile] = useState({
        userType: '',
        name: '',
        first_name: '',
        last_name: '',
        phoneNumber: '',
        about: '',
        email: currentUser?.email || '',
        tags: [],
    });
    const [newTag, setNewTag] = useState('');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch('/api/getUserType', { credentials: 'include' });
                const data = await response.json();
                console.log("Fetched Profile Data:", data);
                if (response.ok) {
                    setProfile({
                        ...data.userData,
                        userType: data.userType,
                        email: currentUser?.email || '',
                        about: data.userData.about || '',
                        phoneNumber: data.userData.phoneNumber || '',
                        tags: data.userData.tags || [],
                        name: data.userType === 'company'
                            ? data.userData.name || 'Unknown Company'
                            : `${data.userData.first_name || ''} ${data.userData.last_name || ''}`.trim(),
                        first_name: data.userData.first_name || '',
                        last_name: data.userData.last_name || '',
                    });
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchUserProfile();
    }, []);

    const handleChange = (prop) => (event) => {
        setProfile({ ...profile, [prop]: event.target.value });
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleClose = () => {
        setEditMode(false);
    };

    const handleSave = async () => {
        try {
            const profileData = {
                userType: profile.userType,
                name: profile.userType === 'company' ? profile.name : undefined,
                first_name: profile.userType === 'influencer' ? profile.first_name : undefined,
                last_name: profile.userType === 'influencer' ? profile.last_name : undefined,
                phoneNumber: profile.phoneNumber,
                about: profile.about,
                email: profile.email,
            };

            const endpoint = profile.userType === 'company'
                ? `/api/updateCompany`
                : `/api/updateInfluencer`;

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(profileData),
            });

            if (!response.ok) {
                throw new Error('Failed to update profile...');
            }

            if (profile.userType !== 'company') {
                const tagsResponse = await fetch(`/api/updateInfluencerTags`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ tags: profile.tags }),
                });

                if (!tagsResponse.ok) {
                    throw new Error('Failed to update tags...');
                }
            }

            setEditMode(false);
        } catch (error) {
            console.error('Failed to update profile:', error);
        }
    };

    const handleAddTag = () => {
        if (newTag && !profile.tags.includes(newTag)) {
            setProfile(prev => ({ ...prev, tags: [...prev.tags, newTag] }));
            setNewTag('');
        }
    };

    const handleDeleteTag = (tagToDelete) => () => {
        setProfile(prev => ({
            ...prev,
            tags: prev.tags.filter(tag => tag !== tagToDelete)
        }));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 3 }}>
            <Typography variant="h4" gutterBottom>User Profile</Typography>
            <Avatar sx={{ width: 56, height: 56, bgcolor: 'primary.main' }}>
                {profile.userType === 'company'
                    ? profile.name?.[0] || 'C'
                    : profile.first_name?.[0] || 'U'}
            </Avatar>
            <Typography variant="h6">
                {profile.userType === 'company'
                    ? profile.name
                    : `${profile.first_name} ${profile.last_name}`.trim()}
            </Typography>
            {!editMode && (
                <Box sx={{ width: '100%' }}>
                    <Typography variant="body1"><strong>Email:</strong> {profile.email}</Typography>
                    <Typography variant="body1"><strong>Phone Number:</strong> {profile.phoneNumber}</Typography>
                    <Typography variant="body1"><strong>About:</strong> {profile.about}</Typography>
                    {profile.userType !== 'company' && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1"><strong>Tags:</strong></Typography>
                            {(profile.tags || []).map((tag, index) => (
                                <Chip key={index} label={tag} onDelete={handleDeleteTag(tag)} sx={{ m: 0.5 }} />
                            ))}
                        </Box>
                    )}
                </Box>
            )}
            <Button onClick={handleEdit} variant="outlined" sx={{ mb: 2 }}>Edit Profile</Button>
            <Dialog open={editMode} onClose={handleClose}>
                <DialogTitle>Edit Your Profile</DialogTitle>
                <DialogContent>
                    {profile.userType === 'company' ? (
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Company Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={profile.name}
                            onChange={handleChange('name')}
                        />
                    ) : (
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="first_name"
                                label="First Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={profile.first_name}
                                onChange={handleChange('first_name')}
                            />
                            <TextField
                                margin="dense"
                                id="last_name"
                                label="Last Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={profile.last_name}
                                onChange={handleChange('last_name')}
                            />
                        </>
                    )}
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={profile.email}
                        onChange={handleChange('email')}
                    />
                    <TextField
                        margin="dense"
                        id="phoneNumber"
                        label="Phone Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={profile.phoneNumber}
                        onChange={handleChange('phoneNumber')}
                    />
                    <TextField
                        margin="dense"
                        id="about"
                        label="About Me"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={profile.about}
                        onChange={handleChange('about')}
                    />
                    {profile.userType !== 'company' && (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <TextField
                                    margin="dense"
                                    id="newTag"
                                    label="Add a tag"
                                    type="text"
                                    variant="outlined"
                                    value={newTag}
                                    onChange={(e) => setNewTag(e.target.value)}
                                />
                                <Button onClick={handleAddTag} variant="contained" sx={{ ml: 1 }}>Add Tag</Button>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                {(profile.tags || []).map((tag, index) => (
                                    <Chip key={index} label={tag} onDelete={handleDeleteTag(tag)} sx={{ m: 0.5 }} />
                                ))}
                            </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save Changes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ProfilePage;
