import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    Container, Snackbar
} from '@mui/material';
import InfluencerForm from "./InfluencerForm";
import CompanyForm from "./CompanyForm";
import { useAuth } from '../../contexts/authContext';

const steps = ['Select Account Type', 'Fill Out Information', 'Start Connecting'];

export default function CustomStepper() {
    const [activeStep, setActiveStep] = useState(0);
    const [accountType, setAccountType] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formData, setFormData] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { setCurrentUser } = useAuth();

    const userId = location.state?.userId;
    const email = location.state?.email;

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const handleNext = () => {
        if (activeStep === 0 && !accountType) {
            setSnackbarMessage("You must select an account type.");
            setSnackbarOpen(true);
            return;
        }

        if (activeStep === 1 && !formSubmitted) {
            setSnackbarMessage("You must complete and submit the form.");
            setSnackbarOpen(true);
            return;
        }


        if (activeStep === 2) {
            // Finalize registration on the last step
            finalizeRegistration();
        }


        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // Receive form data from InfluencerForm
    const onFormDataSubmit = (data) => {
        setFormData(data);
        setActiveStep((prev) => prev + 1);  // Move to the next step
    };

    const finalizeRegistration = async () => {
        try {
            const response = await fetch('/api/finalize-registration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (response.ok) {
                console.log("Set currnet user in customer steppers: data.user", data.user);
                setCurrentUser(data.user);
                setSnackbarMessage("Registration completed successfully.");
                setSnackbarOpen(true);
               // navigate('/home');  // Redirect to dashboard
            } else {
                setSnackbarMessage(`Registration failed: ${data.message}`);
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(`An error occurred: ${error.message}`);
            setSnackbarOpen(true);
        }
    };

    const StepTitle = ({step}) => {
        const titles = [
            "Step 1: Select Account Type",
            "Step 2: Fill Out Information",
            "Step 3: Start Connecting"
        ];
        return (
            <Box>
                <Typography variant="h4" fontWeight="bold" textAlign="center">{titles[step]}</Typography>
            </Box>
        );
    };

    const StepContent = ({step}) => {
        switch (step) {
            case 0:
                return (
                    <Box>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="account-type"
                                name="account-type"
                                value={accountType}
                                onChange={(event) => setAccountType(event.target.value)}
                            >
                                <FormControlLabel value="influencer" control={<Radio/>} label="Influencer"/>
                                <FormControlLabel value="company" control={<Radio/>} label="Company"/>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                );
            case 1:
                return accountType === "influencer" ? (
                    <InfluencerForm
                        onFormDataSubmit={onFormDataSubmit}
                        userId={userId}  // Pass userId to the form
                        email={email}
                    />
                ) : (
                    <CompanyForm
                        onFormDataSubmit={onFormDataSubmit}
                        userId={userId}  // Pass userId to the form
                        email={email}
                    />
                );
            case 2:
                return (
                    <Typography textAlign="center">
                        Thank you for your submission. You can view your profile on your dashboard.
                    </Typography>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ width: '100%', mt: 5 }}>
                <StepTitle step={activeStep} />
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 2 }}>
                    {activeStep === steps.length ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 2 }}>
                            <Typography>All steps completed - you're finished</Typography>
                            <Button
                                onClick={() => navigate('/home')}  // Navigate to home page
                                sx={{ mt: 1 }}
                                variant="contained"
                                color="primary"
                            >
                                Continue
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{ mt: 2 }}>
                            <StepContent step={activeStep} />
                            <Snackbar
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                open={snackbarOpen}
                                autoHideDuration={6000}
                                onClose={handleCloseSnackbar}
                                message={snackbarMessage}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
                                <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                    Back
                                </Button>
                                <Button variant="contained" onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Container>
    );
}
