// supabaseClient.js
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://eyofsgbyjsrgmffdcuth.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImV5b2ZzZ2J5anNyZ21mZmRjdXRoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjkxNDAyNDQsImV4cCI6MjA0NDcxNjI0NH0.ZKKNDdWCE4hrS8JGHMxX-0xeEvakjMYJ2kdljMp4NeY';
console.log(process.env.REACT_APP_SUPABASE_URL);
console.log(process.env.REACT_APP_SUPABASE_ANON_KEY);
if (!supabaseUrl || !supabaseAnonKey) {
    throw new Error('Supabase URL or key is missing!');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey)
