import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const CompanyForm = ({ onFormDataSubmit, userId, email }) => {
    const [companyName, setCompanyName] = useState("");
    const [address, setAddress] = useState("");
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!userId || !email) {
            setErrorMessage('User ID or email is missing.');
            return;
        }

        // Pass the form data back to the parent component
        onFormDataSubmit({
            userId,
            email,
            companyName,
            address,
            accountType: 'company'
        });
    };

    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
                width: '100%',
                maxWidth: 400,
                border: 1,
                borderRadius: '16px',
                borderColor: 'text.primary',
                p: 3,
                boxShadow: 3,
            }}
            onSubmit={handleSubmit}
        >
            <Typography variant="h5" fontWeight="bold" color="text.primary" mb={2}>
                Company Registration
            </Typography>
            <TextField
                required
                label="Company Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                InputLabelProps={{
                    style: { color: 'primary.main' }, // Label color
                }}
                InputProps={{
                    style: { color: 'text.primary' }, // Input text color
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'text.primary', // Default border color
                        },
                        '&:hover fieldset': {
                            borderColor: 'primary.main', // Hover border color
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'primary.main', // Focused border color
                        },
                    },
                }}
            />
            <TextField
                required
                label="Address"
                variant="outlined"
                fullWidth
                margin="normal"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                InputLabelProps={{
                    style: { color: 'primary.main' }, // Label color
                }}
                InputProps={{
                    style: { color: 'text.primary' }, // Input text color
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'text.primary', // Default border color
                        },
                        '&:hover fieldset': {
                            borderColor: 'primary.main', // Hover border color
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'primary.main', // Focused border color
                        },
                    },
                }}
            />
            {errorMessage && (
                <Typography color="error" fontWeight="bold">
                    {errorMessage}
                </Typography>
            )}
            <Button
                type="submit"
                variant="outlined"
                fullWidth
                sx={{
                    borderColor: 'text.primary', // Default border color
                    color: 'text.primary', // Text color
                    '&:hover': {
                        borderColor: 'primary.main', // Hover border color
                        backgroundColor: 'primary.main', // Hover background color
                        color: 'background.default', // Hover text color
                    },
                }}
            >
                Submit
            </Button>
        </Box>
    );
};

export default CompanyForm;
