import React, { useState } from 'react';
import { Typography, Box, TextField, Button, MenuItem, Select, FormControl, InputLabel, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ContactPage = () => {
    const [topic, setTopic] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleTopicChange = (event) => {
        setTopic(event.target.value);
        setShowForm(true);
    };

    const handleChange = (prop) => (event) => {
        setFormData({ ...formData, [prop]: event.target.value });
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch('/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    topic, // Include the selected topic
                }),
            });

            if (response.ok) {
                alert('Your message has been sent!');
                console.log(`Sending to ${topic} department with data:`, formData);
                setFormData({ name: '', email: '', message: '' }); // Reset the form
                setTopic(''); // Reset the topic
                setShowForm(false); // Hide the form
            } else {
                alert('Failed to send your message. Please try again later.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('An error occurred. Please try again later.');
        }
    };

    const faqData = [
        { question: "How can I contact support?", answer: "You can contact support by selecting 'Support' from the topic dropdown above, then submitting the form." },
        { question: "When will the platform be available?", answer: "We will be launching in early 2025!" },
        { question: "How can I report a bug?", answer: "Select 'Report Bug' from the topic dropdown, and describe the issue in detail in the message section." },
        { question: "What is the cost?", answer: "The platform will be completely free for influencers. For company pricing, please contact sales." }
    ];

    const goldBorderStyle = {
        border: '2px solid #EECA74',
        borderRadius: '8px',
        backgroundColor: '#000000',
        color: '#EECA74'
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#EECA74' }}>Contact Us</Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#EECA74' }}>
                Select a topic to help us direct your message to the right team.
            </Typography>

            <FormControl variant="outlined" sx={{ minWidth: 240, mt: 2 }}>
                <InputLabel id="contact-topic-label" sx={{ color: '#EECA74' }}>Select a Topic</InputLabel>
                <Select
                    labelId="contact-topic-label"
                    id="contact-topic"
                    value={topic}
                    onChange={handleTopicChange}
                    label="Select a Topic"
                    sx={{
                        color: '#EECA74',
                        backgroundColor: '#000000',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#EECA74',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#EECA74',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#EECA74',
                        },
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                backgroundColor: '#000000',
                                color: '#EECA74',
                                '& .MuiMenuItem-root': {
                                    color: '#EECA74',
                                    '&:hover': {
                                        backgroundColor: '#EECA74',
                                        color: '#000000',
                                    },
                                },
                            },
                        },
                    }}
                >
                    <MenuItem value="Questions">Questions</MenuItem>
                    <MenuItem value="Support">Support</MenuItem>
                    <MenuItem value="Report Bug">Report Bug</MenuItem>
                    <MenuItem value="Sales">Sales</MenuItem>
                </Select>
            </FormControl>

            {showForm && (
                <Box sx={{ width: '100%', maxWidth: 600, mt: 4, ...goldBorderStyle, padding: 2 }}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.name}
                        onChange={handleChange('name')}
                        InputLabelProps={{ style: { color: '#EECA74' } }}
                        InputProps={{
                            style: { color: 'white' }, // Set input text to white
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#EECA74',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#EECA74',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#EECA74',
                                },
                            },
                        }}
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.email}
                        onChange={handleChange('email')}
                        InputLabelProps={{ style: { color: '#EECA74' } }}
                        InputProps={{
                            style: { color: 'white' }, // Set input text to white
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#EECA74',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#EECA74',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#EECA74',
                                },
                            },
                        }}
                    />
                    <TextField
                        label="Message"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={handleChange('message')}
                        InputLabelProps={{ style: { color: '#EECA74' } }}
                        InputProps={{
                            style: { color: 'white' }, // Set input text to white
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#EECA74',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#EECA74',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#EECA74',
                                },
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2, backgroundColor: '#EECA74', color: '#000000' }}
                        onClick={handleSubmit}
                    >
                        Send Message
                    </Button>
                </Box>
            )}

            <Box sx={{ width: '100%', maxWidth: 600, mt: 6 }}>
                <Typography variant="h5" gutterBottom sx={{ color: '#EECA74' }}>Frequently Asked Questions</Typography>
                {faqData.map((faq, index) => (
                    <Accordion key={index} sx={{ ...goldBorderStyle, marginBottom: 2 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: '#EECA74' }} />}
                            aria-controls={`faq-content-${index}`}
                            id={`faq-header-${index}`}
                        >
                            <Typography sx={{ color: '#EECA74' }}>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ color: '#EECA74' }}>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Box>
    );
};

export default ContactPage;
