import React, { useState } from 'react';
// The EditJobForm component displays beneath the job details when the "Edit" button is clicked.
// This form allows the user to modify the title, description, and location of a job. The state of each field
// is managed with individual useState hooks, ensuring that the form is responsive and the data is up-to-date.
// Upon submitting the form, the updated job information is saved and can be sent back to the database for persistence.

function EditJobForm({ selectedJob, onSave }) {
  const [title, setTitle] = useState(selectedJob.title);
  const [description, setDescription] = useState(selectedJob.description);
  const [location, setLocation] = useState(selectedJob.location);
  const [isPublic, setIsPublic] = useState(selectedJob.is_public);

  // Handle changes in the form inputs
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleIsPublicChange = (event) => {
    setIsPublic(event.target.checked);
  };

  const handleSave = async () => {
    const response = await fetch(`/api/jobs-updates/${selectedJob.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title,
        description,
        location,
        isPublic,
      }),
    });

    if (response.ok) {
      alert('Job updated successfully');
      onSave();
    } else {
      const errorData = await response.json();
      alert(`Failed to update job: ${errorData.message}`);
    }
  };

  return (
      <div className="edit-job-container">
        <h1>Edit Job: {title}</h1>
        <div>
          <label>Title:</label>
          <input
              type="text"
              value={title}
              onChange={handleTitleChange}
          />
        </div>
        <div>
          <label>Description:</label>
          <textarea
              value={description}
              onChange={handleDescriptionChange}
          />
        </div>
        <div>
          <label>Location:</label>
          <input
              type="text"
              value={location}
              onChange={handleLocationChange}
          />
        </div>
        <div>
          <label>
            <input
                type="checkbox"
                checked={isPublic}
                onChange={handleIsPublicChange}
            />
            Public Job
          </label>
        </div>
        <button onClick={handleSave}>Save Changes</button>
      </div>
  );
}

export default EditJobForm;
