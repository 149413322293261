// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDqpVLlp4SBxpDXl9MvcWtwPpm4-rZbOMw",
    authDomain: "sandbox-5c77b.firebaseapp.com",
    projectId: "sandbox-5c77b",
    storageBucket: "sandbox-5c77b.appspot.com",
    messagingSenderId: "1002098956781",
    appId: "1:1002098956781:web:85c87be4bb97b2be092fe4",
    measurementId: "G-R148K9VG8B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export {app, auth}