import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    TextField,
    Stack,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Checkbox,
    FormControlLabel,
    Link
} from "@mui/material";
import TermsAndServices from "./termsAndServices";
import { supabase } from '../../../supabase/supabaseClient'

const Register = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [termsOpen, setTermsOpen] = useState(false);


    const handleOpenTerms = () => {
        setTermsOpen(true);
    };

    const handleCloseTerms = () => {
        setTermsOpen(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        // Validation for password matching and length
        if (password.length < 6 || confirmPassword.length < 6 || confirmPassword !== password) {
            setErrorMessage("Invalid password or confirmation.");
            return;
        }

        setIsRegistering(true);
        try {
            const { data, error } = await supabase.auth.signUp({
                email: email,
                password: password,
            });

            if (error) {
                // Handle error from Supabase
                setErrorMessage(`Registration failed: ${error.message}`);
            } else {
                // On successful sign-up, navigate to the Custom Stepper
                navigate('/CustomStepper', { state: { userId: data.user.id, email: email } });
            }
        } catch (error) {
            setErrorMessage(`Registration failed: ${error.message}`);
        } finally {
            setIsRegistering(false);
        }
    };

    return (
        <Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box
                sx={{
                    width: 384,
                    p: 4,
                    boxShadow: 3,
                    border: 1,
                    borderColor: 'text.primary', // Use theme color for border
                    borderRadius: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography variant="h5" textAlign="center" fontWeight="bold" color="text.primary">Create a New Account</Typography>
                <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Email"
                        type="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            style: { color: 'primary.main' }, // Label color from theme
                        }}
                        InputProps={{
                            style: { color: 'text.primary' }, // Text color from theme
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'text.primary', // Default border color from theme
                                },
                                '&:hover fieldset': {
                                    borderColor: 'primary.main', // Hover border color from theme
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'primary.main', // Focused border color from theme
                                },
                            },
                        }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        autoComplete="new-password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            style: { color: 'primary.main' }, // Label color from theme
                        }}
                        InputProps={{
                            style: { color: 'text.primary' }, // Text color from theme
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'text.primary', // Default border color from theme
                                },
                                '&:hover fieldset': {
                                    borderColor: 'primary.main', // Hover border color from theme
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'primary.main', // Focused border color from theme
                                },
                            },
                        }}
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        autoComplete="off"
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            style: { color: 'primary.main' }, // Label color from theme
                        }}
                        InputProps={{
                            style: { color: 'text.primary' }, // Text color from theme
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'text.primary', // Default border color from theme
                                },
                                '&:hover fieldset': {
                                    borderColor: 'primary.main', // Hover border color from theme
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'primary.main', // Focused border color from theme
                                },
                            },
                        }}
                    />
                    <FormControlLabel
                        control={<Checkbox required sx={{ color: 'text.primary' }} />}
                        label={<Link onClick={handleOpenTerms} underline="hover" sx={{ color: 'primary.main' }}>I accept the Terms and Services.</Link>}
                        sx={{ color: 'text.primary' }}
                    />
                    {errorMessage && (
                        <Typography color="error" fontWeight="bold">{errorMessage}</Typography>
                    )}
                    <Button
                        type="button"
                        variant="contained"
                        disabled={true} // Disable the button
                        fullWidth
                        sx={{
                            backgroundColor: '#888', // Grey background for disabled state
                            color: '#FFF', // White text
                            '&.Mui-disabled': {
                                backgroundColor: '#888', // Maintain grey background when disabled
                                color: '#FFF', // Maintain white text when disabled
                            },
                        }}
                    >
                        Sign Up (Coming Soon)
                    </Button>
                    <Typography variant="body2" textAlign="center" color="text.primary">
                        Already have an account?{' '}
                        <Link
                            onClick={() => navigate("/login")}
                            underline="hover"
                            sx={{ color: 'primary.main' }}
                        >
                            Login
                        </Link>
                    </Typography>
                </Box>
            </Box>
            <Dialog
                open={termsOpen}
                onClose={handleCloseTerms}
                aria-labelledby="terms-dialog-title"
            >
                <DialogTitle id="terms-dialog-title">Terms and Services</DialogTitle>
                <DialogContent>
                    <TermsAndServices />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTerms} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Register;
