import React from 'react';
import { Box, Typography, Button, Chip } from '@mui/material';

const ApplyToJob = ({ job, onBack }) => {
    const handleApply = async () => {
        try {
            const response = await fetch('/api/applyToJob', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ jobId: job.id }),
                credentials: 'include',
            });

            const data = await response.json();
            if (data.status === 'success') {
                alert('Successfully applied for the job!');
            } else {
                alert('Failed to apply for the job.');
            }
        } catch (error) {
            console.error('Error applying for the job:', error);
        }
    };

    return (
        <Box>
            <Button onClick={onBack} sx={{ color: '#EECA74' }}>
                Back to Job Board
            </Button>
            <Typography variant="h4" gutterBottom sx={{ color: '#EECA74' }}>
                {job.title}
            </Typography>
            <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                {job.description}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: '#EECA74', mt: 2 }}>
                Location: {job.location || 'N/A'}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: '#EECA74', mt: 1 }}>
                Tags:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                {job.tags.map((tag, idx) => (
                    <Chip
                        key={idx}
                        label={tag}
                        sx={{
                            mr: 1,
                            color: '#000000',
                            backgroundColor: '#EECA74',
                        }}
                    />
                ))}
            </Box>
            <Button
                variant="contained"
                onClick={handleApply}
                sx={{
                    mt: 3,
                    backgroundColor: '#EECA74',
                    color: '#000000',
                }}
            >
                Apply for this Job
            </Button>
        </Box>
    );
};

export default ApplyToJob;
