import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button } from '@mui/material';

const JobApplicants = ({ jobId }) => {
    const [applicants, setApplicants] = useState([]);
    const [selectedApplicant, setSelectedApplicant] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch applicants list with full profile data
    useEffect(() => {
        const fetchApplicants = async () => {
            setLoading(true);
            try {
                const response = await fetch(`/api/jobs/${jobId}/applicants`, {
                    method: 'GET',
                    credentials: 'include',
                });
                if (!response.ok) throw new Error('Failed to fetch applicants');
                const data = await response.json();

                // Filter out rejected applicants
                const filteredData = data.filter(applicant => applicant.status !== 'rejected');
                setApplicants(filteredData);
            } catch (e) {
                console.error('Fetching applicants failed:', e);
                setError(e.message);
            } finally {
                setLoading(false);
            }
        };
        fetchApplicants();
    }, [jobId]);

    const handleSelectApplicant = (applicant) => {
        setSelectedApplicant(applicant); // Directly set applicant as selected
    };

    const handleAccept = async () => {
        try {
            const response = await fetch(`/api/jobs/${jobId}/applicants/${selectedApplicant.id}/accept`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (response.ok) {
                alert('Applicant accepted!');
                // Update the applicant's status in the frontend state
                setApplicants(prevApplicants =>
                    prevApplicants.map(applicant =>
                        applicant.id === selectedApplicant.id ? { ...applicant, status: 'accepted' } : applicant
                    )
                );
                setSelectedApplicant(null); // Reset selected applicant
            } else {
                console.error('Failed to accept applicant.');
            }
        } catch (error) {
            console.error('Error accepting applicant:', error);
        }
    };

    const handleReject = async () => {
        try {
            const response = await fetch(`/api/jobs/${jobId}/applicants/${selectedApplicant.id}/reject`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (response.ok) {
                alert('Applicant rejected.');
                // Remove the rejected applicant from the frontend state
                setApplicants(prevApplicants =>
                    prevApplicants.filter(applicant => applicant.id !== selectedApplicant.id)
                );
                setSelectedApplicant(null); // Reset selected applicant
            } else {
                console.error('Failed to reject applicant.');
            }
        } catch (error) {
            console.error('Error rejecting applicant:', error);
        }
    };

    if (loading) return <Typography>Loading applicants...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box>
            <Typography variant="h5" gutterBottom>Applicants for Job ID: {jobId}</Typography>
            {!selectedApplicant ? (
                <List>
                    {applicants.map((applicant, index) => (
                        <ListItem
                            key={index}
                            button
                            onClick={() => handleSelectApplicant(applicant)} // Set selected applicant directly
                        >
                            <ListItemText
                                primary={`${applicant.first_name} ${applicant.last_name} - ${applicant.status}`}
                                secondary={`Username: ${applicant.username}`}
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Box>
                    <Button onClick={() => setSelectedApplicant(null)} color="primary" sx={{ mb: 2 }}>
                        Back to Applicants List
                    </Button>
                    <Typography variant="h6" gutterBottom>{`${selectedApplicant.first_name} ${selectedApplicant.last_name}`}</Typography>
                    <Typography variant="body1">Username: {selectedApplicant.username}</Typography>
                    <Typography variant="body1">Phone: {selectedApplicant.phone_number}</Typography>
                    <Typography variant="body1">About: {selectedApplicant.about}</Typography>
                    <Typography variant="body1">
                        Tags: {(selectedApplicant.tags || []).join(', ')}
                    </Typography> {/* Ensure tags is an array */}
                    <Button variant="contained" color="primary" onClick={handleAccept} sx={{ mt: 2, mr: 2 }}>
                        Accept
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleReject} sx={{ mt: 2 }}>
                        Reject
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default JobApplicants;
