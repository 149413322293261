// FeatureGrid.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const FeatureGrid = ({ title, features }) => {
    return (
        <Box sx={{ width: '100%', py: 8, textAlign: 'center', color: '#FFFFFF' }}>
            {/* Title with auto-scaling lines */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 4 }}>
                <Box sx={{ flex: 1, height: '2px', backgroundColor: '#EECA74' }} />
                <Typography variant="h5" sx={{ color: '#FFFFFF' }}>{title}</Typography>
                <Box sx={{ flex: 1, height: '2px', backgroundColor: '#EECA74' }} />
            </Box>

            {/* Grid of feature boxes */}
            <Grid container spacing={3} justifyContent="center">
                {features.map((feature, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                            sx={{
                                border: '2px solid #EECA74',
                                borderRadius: '8px',
                                padding: 4,
                                height: '200px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#FFFFFF',
                                backgroundColor: '#000000',
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="h6">{feature}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default FeatureGrid;
