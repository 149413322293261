import React from 'react';
import { Grid, Paper, List, ListItem, ListItemText, Typography } from '@mui/material';

const CommonFrame = ({ items, children, onSelectItem, searchBar }) => {
    return (
        <Grid container spacing={2} sx={{ backgroundColor: 'black' }}>
            {/* Sidebar for Selectable List */}
            <Grid item xs={12} md={4}>
                <Paper
                    elevation={1}
                    sx={{
                        maxHeight: 'calc(100vh - 64px)',
                        overflow: 'auto',
                        backgroundColor: 'black',
                        border: '1px solid white',
                        color: 'white',
                    }}
                >
                    {searchBar}
                    <List>
                        {items.map((item, index) => (
                            <ListItem button key={index} onClick={() => onSelectItem?.(item)}>
                                <ListItemText primary={item.title} sx={{ color: 'white' }} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Grid>

            {/* Main Content Area */}
            <Grid item xs={12} md={8}>
                <Paper
                    elevation={1}
                    sx={{
                        padding: 2,
                        backgroundColor: 'black',
                        border: '1px solid white',
                        color: 'white',
                    }}
                >
                    {/* Display any children passed in from the parent (e.g., selected job details) */}
                    {children}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CommonFrame;
